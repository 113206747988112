import { ApolloClient, InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import config from './config'

const _overwriteExisting = () => {
    return {
        merge: false, // Do not merge existing and incoming, overwrite existing
    }
}

//Create the caching strategy for Apollo client.
const apolloCacheManager = () => {
    return new InMemoryCache({
        typePolicies: {
            Narrative: {
                fields: {
                    dealOverviewSections: _overwriteExisting(),
                    waiverSections: _overwriteExisting(),
                    strengthSections: _overwriteExisting(),
                    participantSections: _overwriteExisting(),
                    propertySections: _overwriteExisting(),
                    affordabilitySections: _overwriteExisting(),
                    managementSections: _overwriteExisting(),
                    marketSections: _overwriteExisting(),
                    underwritingSections: _overwriteExisting(),
                    mapSections: _overwriteExisting(),
                },
            },
        },
    })
}

const client = new ApolloClient({
    link: createUploadLink({
        uri: `${config.NODE_API}/graphql`,
    }),
    cache: apolloCacheManager(),
})

export default client
