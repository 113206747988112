import React from 'react'
import Logo from '../../../../theme/dealnarrative/icons/NorthMARQ logo.png'
import dateformat from 'dateformat'
import SeecaresLogo from '../../../../theme/dealnarrative/icons/Seecares logo.png'
import { DoRerenderPagesByPDFSize } from './ReportConfig'

type Props = {
    className?: string
    title?: string
    subtitle?: string
    children?: React.ReactNode
}

export default function BasePageLayout(props: Props) {
    const { className, title, subtitle, children } = props
    const showHeaderAndFooter = !DoRerenderPagesByPDFSize
    return (
        <div className={`BasePageLayout d-flex flex-column ${className || ''}`} src-title={title} src-subtitle={subtitle} src-class={className||''}>
            {showHeaderAndFooter && (
                <div className={'pageHeader'}>
                    <div className={'logoOverlay'}>
                        <img src={Logo} alt={'NorthMARQ logo'} />
                    </div>
                    <h1>{title}</h1>
                    <h2>{subtitle}</h2>
                </div>
            )}
            <div className={'pageContent'}>
                <div className={'content'}>{children}</div>
            </div>
            {showHeaderAndFooter && (
                <div className={'pageFooter dateMention d-flex justify-content-between'}>
                    <div className={'date'}>{`Report generated ${dateformat(new Date(), 'mmmm dd, yyyy')}`}</div>
                    <div className={'poweredBy'}>
                        Powered by <img src={SeecaresLogo} alt={'Seecares Logo'} />
                    </div>
                </div>
            )}
        </div>
    )
}
