import React, { useEffect, useMemo, useState } from 'react'
import OpeningPage from './OpeningPage'
import BasePageLayout from './BasePageLayout'
import DealInformationSections from './DealInformationSections'
import SectionLayout from './SectionLayout'
import getImgPath from '../../../../utils/dealnarrative/getImgPath'
import Logo from '../../../../theme/dealnarrative/icons/NorthMARQ logo.png'
import SeecaresLogo from '../../../../theme/dealnarrative/icons/Seecares logo.png'
import dateformat from 'dateformat'
import { DoRerenderPagesByPDFSize } from './ReportConfig'
import { IMAGES } from '../../../../utils/constants/assets'
import nmlogo from '../../dashboard/tabs/Fannie/quote/northmarq_logo.png'
import { ReduxHelper } from '../../../../store'

type Props = {
    narrative: any
    availableTables: any
}

export default function HtmlReport(props: Props) {
    const { narrative, availableTables } = props
    const [accountlogo, setLogo] = useState({ alt: '', src: '' })
    useEffect(() => {
        // init google analytics
        const accountLogoUrl = localStorage?.getItem('accountLogoUrl')

        if (accountLogoUrl && accountLogoUrl !== 'null') {
            setLogo(() => ({
                alt: localStorage.getItem('accountName') || '',
                src: '/v1/' + accountLogoUrl || '',
            }))
        } else {
            setLogo(() => ({
                alt: 'Seecares',
                src: IMAGES.SEECARES_LOGO,
            }))
        }
        //setUsername(localStorage.getItem('username') || '')
    }, [])

    const [isNM, setIsNM] = useState(false)
    useEffect(() => {
        if (localStorage) {
            const accountName = localStorage.getItem('accountName')
            if (accountName?.toLowerCase().indexOf('northmarq') > -1) setIsNM(true)
            if (accountName?.toLowerCase().indexOf('Kobi test') > -1) setIsNM(true)
        }
    }, [])

    const headerSubtitle = [narrative?.dealName, narrative?.propertyAddress].filter((item) => !!item).join(', ')
    document.body.style.visibility = 'hidden'
    useEffect(() => {
        //console.log('HtmlReport useEffect')

        if (!DoRerenderPagesByPDFSize) {
            console.log('pageing not working')
            document.body.style.visibility = 'visible'
            return
        }
        // console.log('HtmlReport useEffect')
        //const pageSize = 'A4'
        let pageSizeClass = 'a4-96dpi'
        let maxHeight = 1123
        let title = ''
        let subtitle = ''
        let className = ''

        let parent = null
        let newReport = null
        // console.log('useEffect pageSize', pageSize)
        // console.log('useEffect pageSize', pageSize)
        // code to run after render goes here

        let updatePagesRunAtLeastOnce = false

        window.addEventListener('load', function () {
            //console.log('load event - updatePages')
            //alert('load event - updatePages')
            updatePages()
            document.body.style.visibility = 'visible'
        })

        setTimeout(() => {
            if (!updatePagesRunAtLeastOnce) {
                updatePages()
                document.body.style.visibility = 'visible'
            }
        }, 2000)
        window.addEventListener(
            'resize',
            function () {
                //if window resizes
                //console.log('rezise')
                location.reload()
            },
            false,
        )

        function updatePages(): void {
            const DEBUG = false
            updatePagesRunAtLeastOnce = true
            // clear paginated view

            //const dpi = document.getElementById('dpi').offsetHeight
            //console.log('dpi', dpi)
            /*
            if (dpi == 74) {
                pageSizeClass = 'a4-74dpi'
                maxHeight = 842
            } else if (dpi == 150) {
                pageSizeClass = 'a4-150dpi'
                maxHeight = 1754
            } else if (dpi == 300) {
                pageSizeClass = 'a4-300dpi'
                maxHeight = 3508
            }
             */

            pageSizeClass = 'a4-150dpiSpecial'
            maxHeight = 1585

            newReport = document.createElement('DIV')
            newReport.id = 'finalReport'
            newReport.classList.add('HtmlReport')

            // get a new page and add it to the paginated view

            let lastEl: any
            // add content childrens to the page one by one
            const HtmlReport = document.getElementsByClassName('HtmlReport')[0]

            parent = HtmlReport.parentNode
            parent.removeChild(HtmlReport)
            //console.log('removeChild', HtmlReport)
            parent.appendChild(newReport)
            //console.log('appendChild', newReport)

            const reportPages = HtmlReport.getElementsByClassName('reportPage')

            reportPages[0].classList.add('page')
            reportPages[0].classList.add(pageSizeClass)

            newReport.appendChild(reportPages[0])

            // console.log('Run on reportPages', reportPages.length, reportPages)
            for (let j = 0; j < reportPages.length; j++) {
                const BasePageLayout = reportPages[j].getElementsByClassName('BasePageLayout')[0]
                if (!BasePageLayout) continue
                if (BasePageLayout?.getAttribute('src-title') && BasePageLayout?.getAttribute('src-title') != '') {
                    title = BasePageLayout.getAttribute('src-title')
                    subtitle = BasePageLayout.getAttribute('src-subtitle')
                    className = BasePageLayout.getAttribute('src-class')
                }

                const pagearr = getNewPage(className)
                let page = pagearr[0]
                let pagecontent = pagearr[1]
                newReport.appendChild(page)
                let moveToNewPage = null
                let moveToNewPageInner = null

                const doReportSection = (currentSection) => {
                    let section = document.createElement('DIV')
                    section.className = currentSection.className
                    if (moveToNewPage) pagecontent.appendChild(moveToNewPage)
                    pagecontent.appendChild(section)

                    const sectionHeader = currentSection.getElementsByTagName('h3')[0]

                    if (sectionHeader) {
                        sectionHeader.parentNode.removeChild(sectionHeader)
                        section.appendChild(sectionHeader)
                    }

                    let sectionContent = null
                    let isFirstSection = false
                    const currentSectionChildren = currentSection.children
                    if (currentSectionChildren && currentSectionChildren.length > 0) {
                        if (DEBUG) console.log('currentSectionChildren', currentSectionChildren)
                        for (let bb = 0; bb < currentSectionChildren.length; bb++) {
                            const child = currentSectionChildren[bb]

                            if (child.className.indexOf('sectionContent') > -1) {
                                if (!sectionContent) {
                                    sectionContent = document.createElement('DIV')
                                    sectionContent.className = 'sectionContent'
                                    section.appendChild(sectionContent)
                                    isFirstSection = true
                                } else {
                                    isFirstSection = false
                                }

                                const all = child.childNodes
                                if (DEBUG)
                                    console.log(
                                        'Run on childeren',
                                        all,
                                        all.length,
                                        'moveToNewPageInner',
                                        moveToNewPageInner,
                                    )

                                let i = -1
                                while (child.childNodes.length > 0) {
                                    i++
                                    const el = child.childNodes[0]

                                    //console.log('el', el)
                                    if (!el) continue

                                    // add the child to the page
                                    //const cloned = el.cloneNode(true)
                                    const cloned = el
                                    cloned.parentNode.removeChild(cloned)

                                    if (moveToNewPageInner) sectionContent.appendChild(moveToNewPageInner)
                                    sectionContent.appendChild(cloned)

                                    // after adding the child if the page scroll hight becomes larger than the page height
                                    // then get a new page and append the child to the  new page
                                    if (DEBUG)
                                        console.log(
                                            '1 maxHeight',
                                            newReport.children.length,
                                            maxHeight,
                                            'page.scrollHeight',
                                            page.scrollHeight,
                                            page.getBoundingClientRect().height,
                                            page.scrollHeight > 1754,
                                            ' pagecontent.getBoundingClientRect().height',
                                            pagecontent.getBoundingClientRect().height,
                                            ' pagecontent.scrollHeight',
                                            pagecontent.scrollHeight,
                                            'pagecontent.getBoundingClientRect().height > maxHeight',
                                            pagecontent.getBoundingClientRect().height > maxHeight,
                                            'pagecontent.scrollHeight > maxHeight',
                                            pagecontent.scrollHeight > maxHeight,
                                            'page.scrollHeight > maxHeight',
                                            page.scrollHeight > maxHeight,
                                            'page.getBoundingClientRect().height > maxHeight',
                                            page.getBoundingClientRect().height > maxHeight,
                                        )
                                    if (
                                        pagecontent.getBoundingClientRect().height > maxHeight ||
                                        pagecontent.scrollHeight > maxHeight ||
                                        page.scrollHeight > maxHeight ||
                                        page.getBoundingClientRect().height > maxHeight
                                    ) {
                                        //console.log('new page')
                                        sectionContent.removeChild(cloned)
                                        if (i == 0 && isFirstSection) {
                                            if (sectionHeader) sectionHeader.parentNode.removeChild(sectionHeader)
                                        }
                                        if (moveToNewPage) moveToNewPage.parentNode.removeChild(moveToNewPage)
                                        if (moveToNewPageInner)
                                            moveToNewPageInner.parentNode.removeChild(moveToNewPageInner)

                                        if (pagecontent.children.length > 0) {
                                            const pagearr = getNewPage(className)
                                            page = pagearr[0]
                                            pagecontent = pagearr[1]
                                            newReport.appendChild(page)
                                        }

                                        if (moveToNewPage) {
                                            pagecontent.appendChild(moveToNewPage)
                                        }
                                        section = document.createElement('DIV')
                                        section.className = currentSection.className

                                        // if (moveToNewPage) pagecontent.appendChild(moveToNewPage)
                                        pagecontent.appendChild(section)
                                        if (i == 0 && isFirstSection) {
                                            if (sectionHeader) section.appendChild(sectionHeader)
                                        }

                                        sectionContent = document.createElement('DIV')
                                        sectionContent.className = 'sectionContent'
                                        if (moveToNewPageInner) section.appendChild(moveToNewPageInner)
                                        section.appendChild(sectionContent)

                                        cloned.style.marginBottom = '30px'
                                        sectionContent.appendChild(cloned)
                                        moveToNewPage = null
                                        moveToNewPageInner = null
                                    } else {
                                        moveToNewPage = null
                                    }
                                    lastEl = cloned
                                }
                            } else {
                                sectionContent = null

                                const cloned = child.cloneNode(true)
                                if (child.getAttribute('src-movetonewpage') == 'true') {
                                    // console.log('movetonewpage in section created', cloned)
                                    moveToNewPageInner = cloned
                                }
                                if (moveToNewPage && moveToNewPage != cloned) section.appendChild(moveToNewPage)
                                section.appendChild(cloned)

                                // after adding the child if the page scroll hight becomes larger than the page height
                                // then get a new page and append the child to the  new page
                                // console.log('page.scrollHeight', page.scrollHeight, page.getBoundingClientRect().height, page.scrollHeight > 1754)
                                if (DEBUG)
                                    console.log(
                                        '2 maxHeight',
                                        newReport.children.length,
                                        maxHeight,
                                        'page.scrollHeight',
                                        page.scrollHeight,
                                        page.getBoundingClientRect().height,
                                        page.scrollHeight > 1754,
                                        ' pagecontent.getBoundingClientRect().height',
                                        pagecontent.getBoundingClientRect().height,
                                        ' pagecontent.scrollHeight',
                                        pagecontent.scrollHeight,
                                        'pagecontent.getBoundingClientRect().height > maxHeight',
                                        pagecontent.getBoundingClientRect().height > maxHeight,
                                        'pagecontent.scrollHeight > maxHeight',
                                        pagecontent.scrollHeight > maxHeight,
                                        'page.scrollHeight > maxHeight',
                                        page.scrollHeight > maxHeight,
                                        'page.getBoundingClientRect().height > maxHeight',
                                        page.getBoundingClientRect().height > maxHeight,
                                    )
                                if (
                                    pagecontent.getBoundingClientRect().height > maxHeight ||
                                    pagecontent.scrollHeight > maxHeight ||
                                    page.scrollHeight > maxHeight ||
                                    page.getBoundingClientRect().height > maxHeight
                                ) {
                                    //console.log('new page')
                                    cloned.parentNode.removeChild(cloned)
                                    if (isFirstSection) {
                                        if (sectionHeader) sectionHeader.parentNode.removeChild(sectionHeader)
                                    }
                                    if (moveToNewPage) section.removeChild(moveToNewPage)

                                    if (pagecontent.children.length > 0) {
                                        const pagearr = getNewPage(className)
                                        page = pagearr[0]
                                        pagecontent = pagearr[1]
                                        newReport.appendChild(page)
                                    }
                                    section = document.createElement('DIV')
                                    section.className = currentSection.className
                                    pagecontent.appendChild(section)
                                    if (moveToNewPage) {
                                        moveToNewPage.style.marginTop = '0px'
                                        pagecontent.insertBefore(moveToNewPage, pagecontent.firstChild)
                                    }

                                    if (isFirstSection) {
                                        if (sectionHeader) section.appendChild(sectionHeader)
                                    }
                                    cloned.style.marginBottom = '30px'
                                    section.appendChild(cloned)
                                    moveToNewPage = null
                                    moveToNewPageInner = null
                                }
                                lastEl = cloned
                            }
                        }
                    }
                }

                const doReportElement = (childElement) => {
                    const cloned = childElement.cloneNode(true)
                    if (childElement.getAttribute('src-movetonewpage') == 'true') {
                        //console.log('movetonewpage outside section', cloned)
                        moveToNewPage = cloned
                    }
                    if (moveToNewPage && moveToNewPage != cloned) {
                        //console.log('movetonewpage outside section adding')
                        pagecontent.appendChild(moveToNewPage)
                    }
                    pagecontent.appendChild(cloned)

                    // after adding the child if the page scroll hight becomes larger than the page height
                    // then get a new page and append the child to the  new page
                    // console.log('page.scrollHeight', page.scrollHeight, page.getBoundingClientRect().height, page.scrollHeight > 1754)
                    if (DEBUG)
                        console.log(
                            ' 3 maxHeight',
                            newReport.children.length,
                            maxHeight,
                            'page.scrollHeight',
                            page.scrollHeight,
                            page.getBoundingClientRect().height,
                            page.scrollHeight > 1754,
                            ' pagecontent.getBoundingClientRect().height',
                            pagecontent.getBoundingClientRect().height,
                            ' pagecontent.scrollHeight',
                            pagecontent.scrollHeight,
                            'pagecontent.getBoundingClientRect().height > maxHeight',
                            pagecontent.getBoundingClientRect().height > maxHeight,
                            'pagecontent.scrollHeight > maxHeight',
                            pagecontent.scrollHeight > maxHeight,
                            'page.scrollHeight > maxHeight',
                            page.scrollHeight > maxHeight,
                            'page.getBoundingClientRect().height > maxHeight',
                            page.getBoundingClientRect().height > maxHeight,
                        )
                    if (
                        pagecontent.getBoundingClientRect().height > maxHeight ||
                        pagecontent.scrollHeight > maxHeight ||
                        page.scrollHeight > maxHeight ||
                        page.getBoundingClientRect().height > maxHeight
                    ) {
                        // console.log('new page')
                        pagecontent.removeChild(cloned)

                        if (pagecontent.children.length > 0) {
                            const pagearr = getNewPage(className)
                            page = pagearr[0]
                            pagecontent = pagearr[1]
                            newReport.appendChild(page)
                        }
                        if (moveToNewPage && moveToNewPage != cloned) {
                            //console.log('movetonewpage outside section adding after new page')
                            pagecontent.appendChild(moveToNewPage)
                        }
                        cloned.style.marginBottom = '30px'
                        pagecontent.appendChild(cloned)

                        moveToNewPage = null
                    }
                    lastEl = cloned
                }
                const reportChildren = BasePageLayout.getElementsByClassName('content')[0].children
                if (reportChildren && reportChildren.length > 0) {
                    // console.log('reportChildren', reportChildren)
                    for (let aa = 0; aa < reportChildren.length; aa++) {
                        if (reportChildren[aa].className.indexOf('sectionGroup') > -1) {
                            const sectionGroupChildren = reportChildren[aa].children
                            for (let cc = 0; cc < sectionGroupChildren.length; cc++) {
                                if (sectionGroupChildren[cc].className.indexOf('ReportSection') > -1) {
                                    doReportSection(sectionGroupChildren[cc])
                                } else {
                                    doReportElement(sectionGroupChildren[cc])
                                }
                            }
                        } else if (reportChildren[aa].className.indexOf('ReportSection') > -1) {
                            doReportSection(reportChildren[aa])
                        } else {
                            // add the child to the page
                            doReportElement(reportChildren[aa])
                        }
                    }
                }
            }

            //bring the element in to view port
            //lastEl.scrollIntoView({ behavior: 'smooth', block: 'nearest' })

            const markReady = document.createElement('DIV')
            markReady.id = 'dealNarativeReady'
            markReady.classList.add('dealNarativeReady')
            parent.appendChild(markReady)
        }

        function getNewPage(className): any {
            const page = document.createElement('DIV')

            page.classList.add('page')
            page.classList.add('reportPage')
            page.classList.add(pageSizeClass)

            const BasePageLayout = document.createElement('DIV')
            BasePageLayout.className = 'BasePageLayout d-flex flex-column ' + className
            page.appendChild(BasePageLayout)

            const pageHeader = document.createElement('DIV')
            pageHeader.className = 'pageHeader'
            const logoOverlay = document.createElement('DIV')
            logoOverlay.className = 'logoOverlay'
            const img = document.createElement('img')

            if (!isNM) {
                img.style.marginTop = '20px'
            }
            img.setAttribute('src', isNM ? Logo : accountlogo.src || IMAGES.SEECARES_LOGO)
            img.setAttribute('alt', isNM ? 'NorthMARQ logo' : accountlogo.alt)
            BasePageLayout.appendChild(pageHeader)
            pageHeader.appendChild(logoOverlay)
            logoOverlay.appendChild(img)
            const h1 = document.createElement('h1')
            h1.innerHTML = title
            const h2 = document.createElement('h2')
            h2.innerHTML = subtitle
            pageHeader.appendChild(h1)
            pageHeader.appendChild(h2)

            const pageContent = document.createElement('DIV')
            pageContent.className = 'pageContent'
            const content = document.createElement('content')
            content.className = 'content'
            content.id = 'pagecontent'

            BasePageLayout.appendChild(pageContent)
            pageContent.appendChild(content)

            const pageFooter = document.createElement('DIV')
            pageFooter.className = 'pageFooter dateMention d-flex justify-content-between'
            BasePageLayout.appendChild(pageFooter)

            const date = document.createElement('DIV')
            date.className = 'date'
            date.innerHTML = 'Report generated ' + dateformat(new Date(), 'mmmm dd, yyyy')
            const poweredBy = document.createElement('DIV')
            poweredBy.className = 'poweredBy'

            const innerHTML = "Powered by <img src='" + SeecaresLogo + "' alt='Seecares Logo' />"

            poweredBy.innerHTML = innerHTML

            const img2 = document.createElement('img')
            img2.setAttribute('src', SeecaresLogo)
            img2.setAttribute('alt', 'Seecares')

            pageFooter.appendChild(date)
            pageFooter.appendChild(poweredBy)

            //console.log("innerHTML",innerHTML)

            return [page, content]
        }
    }, [])

    // A group of sections is visible if at least one section
    // is flagged as visible. Returns false if there are no sections.
    const showSectionGroup = React.useCallback((sections) => {
        return sections && sections.some((section) => section.visible)
    }, [])

    return (
        <>
            <div id="dpi" />

            <div className="HtmlReport">
                <div className={'reportPage'}>
                    <OpeningPage narrative={narrative} />
                </div>
                <div className={'reportPage'}>
                    <BasePageLayout title={'Deal Information'} subtitle={headerSubtitle}>
                        <DealInformationSections narrative={narrative} />
                    </BasePageLayout>
                </div>
                {showSectionGroup(narrative.dealOverviewSections?.slice(0, 1)) && (
                    <div className={'reportPage'}>
                        <BasePageLayout title={'Deal Overview'} subtitle={headerSubtitle}>
                            {narrative.dealOverviewSections?.slice(0, 1).map((section, index) => (
                                <SectionLayout
                                    key={index}
                                    section={section}
                                    className={'overview'}
                                    availableTables={availableTables}
                                    projectId={narrative.projectId}
                                    imgSrc={
                                        index == false && 0 && narrative.projectImage //custromer requested to remove it, keeping the code
                                            ? getImgPath(narrative.projectImage, narrative.projectId)
                                            : null
                                    }
                                />
                            ))}
                        </BasePageLayout>
                    </div>
                )}
                {(showSectionGroup(narrative.dealOverviewSections?.slice(1)) ||
                    showSectionGroup(narrative.waiverSections) ||
                    showSectionGroup(
                        narrative.strengthSections || showSectionGroup(narrative.affordabilitySections),
                    )) && (
                    <div className={'reportPage'}>
                        <BasePageLayout title={'Deal Overview'} subtitle={headerSubtitle}>
                            {showSectionGroup(narrative.dealOverviewSections?.slice(1)) && (
                                <div className={'sectionGroup'}>
                                    {narrative.dealOverviewSections?.slice(1).map((section, index) => (
                                        <SectionLayout
                                            key={index}
                                            section={section}
                                            className={'overview'}
                                            availableTables={availableTables}
                                            projectId={narrative.projectId}
                                        />
                                    ))}
                                </div>
                            )}
                            {showSectionGroup(narrative.waiverSections) && (
                                <div className={'sectionGroup'}>
                                    <div src-movetonewpage="true">
                                        <h3>Waivers and Reasons for Pre-review</h3>
                                    </div>
                                    {narrative.waiverSections?.map((section, index) => (
                                        <SectionLayout
                                            key={index}
                                            section={section}
                                            className={'waivers'}
                                            availableTables={availableTables}
                                            titleSmall={true}
                                            projectId={narrative.projectId}
                                        />
                                    ))}
                                </div>
                            )}
                            {showSectionGroup(narrative.strengthSections) && (
                                <div className={'sectionGroup'}>
                                    <div src-movetonewpage="true">
                                        <h3>Strengths, Risks and Mitigating Factors</h3>
                                    </div>
                                    {narrative.strengthSections?.map((section, index) => (
                                        <SectionLayout
                                            key={index}
                                            section={section}
                                            className={'strengths'}
                                            availableTables={availableTables}
                                            projectId={narrative.projectId}
                                        />
                                    ))}
                                </div>
                            )}
                            {showSectionGroup(narrative.affordabilitySections) &&
                                narrative.affordabilitySections?.map((section, index) => (
                                    <SectionLayout
                                        key={index}
                                        section={section}
                                        className={'affordability'}
                                        availableTables={availableTables}
                                        projectId={narrative.projectId}
                                    />
                                ))}
                        </BasePageLayout>
                    </div>
                )}
                {showSectionGroup(narrative.participantSections) && (
                    <div className={'reportPage'}>
                        <BasePageLayout title={'Participants Overview'} subtitle={headerSubtitle}>
                            {narrative.participantSections?.map((section, index) => {
                                if (index == 0 && (section.title == null || section.title == ''))
                                    section.title = 'Participant Overview'
                                return (
                                    <SectionLayout
                                        key={index}
                                        section={section}
                                        className={'participants'}
                                        availableTables={availableTables}
                                        projectId={narrative.projectId}
                                    />
                                )
                            })}
                        </BasePageLayout>
                    </div>
                )}
                {(showSectionGroup(narrative.propertySections) || showSectionGroup(narrative.managementSections)) && (
                    <div className={'reportPage'}>
                        <BasePageLayout title={'Property Overview'} subtitle={headerSubtitle}>
                            {false && narrative.projectImage && (
                                <img
                                    id={'propertyOverviewImg'}
                                    src={getImgPath(narrative.projectImage, narrative.projectId)}
                                    alt={'Project Image'}
                                />
                            )}
                            {showSectionGroup(narrative.propertySections) &&
                                narrative.propertySections?.map((section, index) => (
                                    <SectionLayout
                                        key={index}
                                        section={section}
                                        className={'property'}
                                        availableTables={availableTables}
                                        projectId={narrative.projectId}
                                    />
                                ))}

                            {showSectionGroup(narrative.managementSections) &&
                                narrative.managementSections?.map((section, index) => (
                                    <SectionLayout
                                        key={index}
                                        section={section}
                                        className={'management'}
                                        availableTables={availableTables}
                                        projectId={narrative.projectId}
                                    />
                                ))}
                        </BasePageLayout>
                    </div>
                )}
                {showSectionGroup(narrative.marketSections) && (
                    <div className={'reportPage'}>
                        <BasePageLayout title={'Market Analysis'} subtitle={headerSubtitle}>
                            {narrative.marketSections?.map((section, index) => (
                                <SectionLayout
                                    key={index}
                                    section={section}
                                    className={'market'}
                                    availableTables={availableTables}
                                    projectId={narrative.projectId}
                                />
                            ))}
                        </BasePageLayout>
                    </div>
                )}
                {showSectionGroup(narrative.underwritingSections) && (
                    <div className={'reportPage'}>
                        <BasePageLayout title={'Underwriting Overview'} subtitle={headerSubtitle}>
                            {narrative.underwritingSections?.map((section, index) => (
                                <SectionLayout
                                    key={index}
                                    section={section}
                                    className={'underwriting'}
                                    availableTables={availableTables}
                                    projectId={narrative.projectId}
                                />
                            ))}
                        </BasePageLayout>
                    </div>
                )}
                {showSectionGroup(narrative.mapSections) && (
                    <div className={'reportPage'}>
                        <BasePageLayout title={'Maps & Photos'} subtitle={headerSubtitle}>
                            {narrative.mapSections?.map((section, index) => (
                                <SectionLayout
                                    key={index}
                                    section={section}
                                    className={'maps'}
                                    availableTables={availableTables}
                                    projectId={narrative.projectId}
                                />
                            ))}
                        </BasePageLayout>
                    </div>
                )}
                {/*language=SCSS*/}
                <style jsx>
                    {`
                        #propertyOverviewImg {
                            display: block;
                            margin: 0 auto;
                        }
                    `}
                </style>
            </div>
        </>
    )
}
