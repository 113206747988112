import React from 'react'
import ReportSection from './ReportSection'
import { NarrativeSectionItemType } from '../../../../store/types/dealnarrative'
import TableFormatter from '../forms/TableFormatter'
import getImgPath from '../../../../utils/dealnarrative/getImgPath'

type Props = {
    section: any
    availableTables: any
    className?: string
    titleSmall?: boolean
    projectId: number
    imgSrc?: string | null
}

export default function SectionLayout(props: Props) {
    const { section, availableTables, className, projectId, imgSrc } = props

    if (!section.visible) return null

    return (
        <ReportSection title={section.title} className={className || ''} titleSmall={props.titleSmall}>
            {section.kpis?.length > 0 && (
                <div className={'kpiRow row justify-content-start'}>
                    {section.kpis.map((kpi, index) => (
                        <React.Fragment key={index}>
                            {kpi.title?.length > 0 && kpi.value?.length > 0 && (
                                <div className={'col-auto'}>
                                    <div className={'kpiCard'}>
                                        <div className={'kpiTitle'}>{kpi.title}</div>
                                        <div className={'kpiValue'}>{kpi.value}</div>
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            )}
            {section.items?.map((item, itemIndex) => {
                if (item.type === NarrativeSectionItemType.BLURB && item.value?.length > 0) {
                    return (
                        <div
                            key={itemIndex}
                            className={'blurb'}
                            style={{ display: itemIndex == 0 && imgSrc ? 'flex' : 'block' }}
                        >
                            <div
                                dangerouslySetInnerHTML={
                                    /* eslint-disable-next-line @typescript-eslint/naming-convention */
                                    { __html: item?.value }
                                }
                            />
                            {itemIndex == 0 && imgSrc && (
                                <img id={'dealOverviewImg'} src={imgSrc} alt={'Project Image'} />
                            )}
                        </div>
                    )
                }
                if (item.type === NarrativeSectionItemType.TABLE && item.value?.length > 0 && availableTables) {
                    return (
                        <div key={itemIndex} className={'table'}>
                            <TableFormatter
                                table={(item && item.address && item.address != ''
                                    ? availableTables.find((file) => file.address === item.address)
                                    : availableTables[0]
                                ).tables.find((table) => table.type === item.value)}
                                columns={item.showColumns}
                            />
                        </div>
                    )
                }
                if (item.type === NarrativeSectionItemType.IMAGE && item.value?.length > 0) {
                    return (
                        <div key={itemIndex} className={'image'}>
                            <img src={getImgPath(item.value, projectId)} />
                        </div>
                    )
                }
                if (item.type === NarrativeSectionItemType.GALLERY) {
                    let urls = []
                    try {
                        urls = JSON.parse(item.value)
                    } catch (e) {
                        // Ignore value when it cannot be parsed
                    }

                    if (urls.length > 0) {
                        // Build the lines of images. Remove empty lines. There are 2 images per line
                        const cleanedUrls = []
                        for (let i = 0; i < urls.length; i = i + 2) {
                            if (urls[i].length > 0 || urls[i + 1].length > 0) {
                                cleanedUrls.push(urls[i], urls[i + 1])
                            }
                        }

                        if (cleanedUrls.length > 0)
                            return (
                                <div key={itemIndex} className={'gallery'}>
                                    {cleanedUrls.map((url, idx) => (
                                        <div key={'cleanedUrls' + idx}>
                                            {url?.length > 0 && <img src={getImgPath(url, projectId)} />}
                                        </div>
                                    ))}
                                </div>
                            )
                    }
                }
            })}

            {/*language=SCSS*/}
            <style jsx>
                {`
                    #dealOverviewImg {
                        width: 313.33px;
                        height: 210.33px;
                        margin-left: 30px;
                    }
                `}
            </style>
        </ReportSection>
    )
}
