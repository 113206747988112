import React from 'react'

import { AppProps } from 'next/app'
import '../../public/_css/bootstrap.min.css'
import '../../public/_css/style.css'
import '../../public/_css/modal.css'
import '../../public/_css/responsive.css'
import '../../public/_css/textarea-fix.css'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import 'react-datepicker/dist/react-datepicker.css'

import 'devextreme/dist/css/dx.common.css'
import '../scss/customDevExGrid.scss'
import { ApolloProvider } from '@apollo/client'
import client from '../utils/dealnarrative/apollo-client'

//Image slider for minisite
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import NarrativeEditorPage from './dealnarrative/editor'
import ReportViewerPage from './dealnarrative/report'
import ReportOMViewerPage from './dealnarrative/om'

// eslint-disable-next-line @typescript-eslint/naming-convention
function CustomApp({ Component, pageProps }: AppProps) {
    //console.log('CustomApp', Component)
    if (Component === NarrativeEditorPage || Component == ReportViewerPage || Component == ReportOMViewerPage) {
        require('../theme/dealnarrative/index.scss')
        return (
            <ApolloProvider client={client}>
                <Component {...pageProps} />
            </ApolloProvider>
        )
    } else {
        return <Component {...pageProps} />
    }
}

export default CustomApp
