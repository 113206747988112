import React, { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import ErrorModal from '../../components/pages/dealnarrative/modals/ErrorModal'
import HtmlReport from '../../components/pages/dealnarrative/report/HtmlReport'
import ReportButtonBar from '../../components/pages/dealnarrative/navigation/ReportButtonBar'
import { NextPageContext } from 'next'
import { gql, useQuery } from '@apollo/client'
import { FullNarrativeFragment } from '../../store/fragments/dealnarrative/fragments'
import { graphQLRequest } from '../../store/graphQLClient'
import { omServerSideWrapper } from '../../utils/server-side/omServerSideWrapper'
import Head from 'next/head'
import { GoogleAnalytics } from '../../components/pages/expose/GoogleAnalytics'
import { store } from '../../store'
import { Provider } from 'react-redux'
import { getEnv } from '../../utils/server-side/getEnv'

export default function ReportViewerPage(props: any) {
    const [isPageLoading, setIsPageLoading] = useState(true)
    const [error, setError] = useState(undefined)

    const router = useRouter()

    // Fetch narrative if a narrativeId is provided in the URL.
    // Performed only after rehydration on the client because router.query is not available until then.
    // Performed only on first page load; subsequent calls look into the Apollo cache (the entity returned must have a prop named "id")
    const { data: narrativeData, loading: isNarrativeLoading, error: narrativeError } = props

    const narrative = narrativeData?.narrativeById

    // Page is loading until router is ready and we are able to get the narrativeId
    // In that case, page is loading until fetching the narrative/project is done.
    useEffect(() => {
        if (router.isReady && !!router.query.narrativeId) setIsPageLoading(isNarrativeLoading)
    }, [router.isReady, isNarrativeLoading, router.query.narrativeId])

    // Set error, if any (we must do that in an effect because we depend on router.isReady).
    useEffect(() => {
        if (router.isReady && !router.query.narrativeId) {
            // URL query must specify narrativeId (fetch narrative)
            setError('You must provide a narrative ID')
        } else if (router.isReady && router.query.narrativeId) {
            // A fetch with narrativeId was attempted
            if (narrativeError)
                // Fetch ended with errors
                setError('Error in response while fetching the narrative')
            else if (!isNarrativeLoading && !narrative)
                // Fetch ended without error but returned nothing
                setError('Cannot find a narrative with this ID')
            else setError(undefined)
        } else {
            // New narrative exists, so no error until we send the mutation
            setError(undefined)
        }
    }, [router.isReady, router.query.narrativeId, narrativeError, isNarrativeLoading, narrative])

    const onClose = useCallback(() => {
        // window.close()
        window.location.href = `/v1/`
    }, [])

    const onDownloadPdf = useCallback(() => {
        //window.print()
        const url = `/export-narrative-pdf/${router.query.id}?id=${router.query.narrativeId}`
        console.log('Download PDF', url)
        window.open(url, '_blank')
    }, [])

    const onShare = useCallback(() => {
        console.log('Share (to be implemented)')
    }, [])

    let content: React.ReactNode

    const availableTables = []
    try {
        if (props.availableTables && props.availableTables.length > 0) {
            for (let i = 0; i < props.availableTables.length; i++) {
                availableTables.push({
                    address: props.availableTables[i].address,
                    tables: props.availableTables[i].tables?.map(({ data, header, header2, ...rest }) => {
                        const out = {
                            data: JSON.parse(data),
                            header: header ? JSON.parse(header) : null,
                            header2: header2 ? JSON.parse(header2) : null,
                            ...rest,
                        }
                        return out
                    }),
                })
            }
        }
    } catch (error) {
        console.error(error)
    }
    if (availableTables)
        availableTables.map((tables) => {
            tables.tables.map((item) => {
                item.title = item.title.replace(/_/g, ' ')
                const words = item.title.split(' ')
                for (let i = 0; i < words.length; i++) {
                    if (words[i].length > 0) words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase()
                }
                item.title = words.join(' ')
            })
        })
    //console.log('availableTables', availableTables)
    if (isPageLoading) {
        // Do not display the form until the page has loaded and narrative has been fetched
        content = <div>Loading...</div>
    } else if (error || !narrative) {
        // Page is done loading, but errors occurred
        content = <ErrorModal show={!!error} message={error} />
    } else {
        content = <HtmlReport narrative={narrative} availableTables={availableTables} />
    }

    return (
        <Provider store={store}>
            <Head>
                <link
                    href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined"
                    rel="stylesheet"
                />
            </Head>
            <div className="ReportViewerPage">
                <div className="mainContainer">
                    <div className={'centerCol'}>{content}</div>
                </div>
                <div className="buttonBarContainer d-print-none">
                    <ReportButtonBar onClose={onClose} onDownloadPdf={onDownloadPdf} onShare={onShare} />
                </div>
            </div>
            <GoogleAnalytics
                apiKey={props.env.GOOGLE_ANALYTICS_KEY}
                narrativeId={+router?.query?.narrativeId}
                projectId={+router?.query?.id}
            />
        </Provider>
    )
}

export async function getServerSideProps(context: NextPageContext) {
    //console.log('dealnarrative report getServerSideProps')
    try {
        return await omServerSideWrapper(context, async (context) => {
            const id = +context.query.narrativeId
            const projectId = +context.query.id
            // console.log('dealnarrative report omServerSideWrapper', id)
            const res = await graphQLRequest(
                gql`
                    ${FullNarrativeFragment}
                    query narrativeById($projectId: Int!, $id: ID!) {
                        narrativeById(projectId: $projectId, id: $id) {
                            ...FullNarrativeFragment
                        }
                    }
                `,
                {
                    projectId,
                    id,
                },
                { 'server-user': 'TRUE' },
            )
            const res1 = await graphQLRequest(
                gql`
                    query dealNarrativeTables($projectId: Int!, $narrativeId: ID!) {
                        dealNarrativeTables(projectId: $projectId, narrativeId: $narrativeId) {
                            address
                            tables {
                                id
                                type
                                title
                                data
                                header
                                header2
                            }
                        }
                    }
                `,
                {
                    projectId,
                    narrativeId: id,
                },
                { 'server-user': 'TRUE' },
            )
            const out = {
                props: {
                    ...getEnv(),
                    data: res.data,
                    availableTables: res1.data.dealNarrativeTables,
                    loading: false,
                    error: null,
                },
            }
            // console.log('out availableTables', res1)
            return out
        })
    } catch (e) {
        console.log('dealnarrative report getServerSideProps error', e)
        const out = {
            props: {
                ...getEnv(),
                data: null,
                availableTables: null,
                loading: false,
                error: null,
            },
        }
        return out
    }
}
